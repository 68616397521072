<template>
    <div class="wrapper">
        <div class="js-rem-example" ref="remEx"></div>
        <div
            class="scroll-button scroll-button--right"
            @mouseenter="onMouseEnter('right')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        >
          <ArrowIcon />
        </div>
        <div
            class="scroll-button scroll-button--left"
            @mouseenter="onMouseEnter('left')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        >
          <ArrowIcon />
        </div>
        <div
            class="scroll-button scroll-button--bottom"
            @mouseenter="onMouseEnter('bottom')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        >
          <ArrowIcon />
        </div>
        <div
            class="scroll-button scroll-button--top"
            @mouseenter="onMouseEnter('top')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        >
          <ArrowIcon />
        </div>
        <div
            class="scroll-button scroll-button--right-top"
            @mouseenter="onMouseEnter('right-top')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        ></div>
        <div
            class="scroll-button scroll-button--right-bottom"
            @mouseenter="onMouseEnter('right-bottom')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        ></div>
        <div
            class="scroll-button scroll-button--left-top"
            @mouseenter="onMouseEnter('left-top')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        ></div>
        <div
            class="scroll-button scroll-button--left-bottom"
            @mouseenter="onMouseEnter('left-bottom')"
            @mouseleave="onMouseLeave"
            v-if="isZooming"
        ></div>

        <button
            class="drag-list__zoom-button button"
            @click="onClickCloseZoom"
            v-if="isZooming"
        >
            <IconMinimize />
        </button>
      <button
          class="drag-list__zoom-button button"
          @click="onClickZoomFirst"
          v-else
      >
        <IconMaximize />
      </button>
        <ul
            class="drag-list"
            v-drag-and-drop:options="options"
            :style="{
                transform: `scale(${stellageZoomStyle.scale}) translate(-${stellageZoomStyle.x}px, -${stellageZoomStyle.y}px)`
            }"
            ref="dragListWrapper"
            :class="{
                active: isZooming,
            }"
        >
            <li
                class="drag-column"
                v-for="zone in zones"
                :key="zone.uuid"
                :class="{ ['stellage' + zone.id]: zone.type === 'shelving', }"
            >
                <ul
                    class="drag-inner-list"
                    :data-id="zone.uuid"
                    v-if="zone.racks"
                >
                    <li
                        class="drag-shelf"
                        :class="[{['p' + zone.id + shelf.pos]: zone.type !== 'basket'}, getClassByColorName(shelf.color)]"
                        v-for="shelf in zone.racks"
                        :key="shelf.uuid"
                        :data-id="shelf.uuid"
                    >
                        <vue-draggable-group
                            v-model="shelf.pens"
                            :groups="zone.racks"
                            :data-id="shelf.uuid"
                            items-key="pens"
                        >
                            <ul
                                v-if="shelf.pens"
                                class="drag-shelf-content"
                                :data-id="shelf.uuid"
                                :data-type="zone.type"
                            >
                                <div
                                    class="drag-shelf__background"
                                    :data-type="zone.type"
                                    @click="onClickRack($event)"
                                    ref="rackBg"
                                ></div>

                                <Item
                                    v-for="item in shelf.pens"
                                    :key="item.uuid"
                                    :item="item"
                                    :shelfId="shelf.uuid"
                                    :zonetype="zone.type"
                                    :isTouchDevice="isTouchDevice"
                                />
                            </ul>
                        </vue-draggable-group>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="drag-list basket"
             @mouseenter="basketHovered()"
             @mouseleave="basketUnHovered()"
             v-drag-and-drop:options="options">
          <div class="drag-column basket" v-on:click="showContentBasket()">
            <ul
                class="drag-inner-list"
                :data-id="basketZone.uuid"
                v-if="basketZone.racks"
                :class="[basketZone.racks[0].pens.length > 0 && isShowContentBasket ? 'active' : '']"
            >
              <li class="basket-total">{{ basketZone.racks[0].pens.length }}</li>
              <li
                  class="drag-shelf basketcontent"
                  v-for="shelf in basketZone.racks"
                  :key="shelf.uuid"
                  :data-id="shelf.uuid"
              >
                <vue-draggable-group
                    v-model="shelf.pens"
                    :groups="basketZone.racks"
                    :data-id="shelf.uuid"
                    items-key="pens"
                >

                  <ul
                      v-if="shelf.pens"
                      class="drag-shelf-content"
                      id="basket"
                      :data-id="shelf.uuid"
                      :data-type="basketZone.type"
                      :class="[basketZone.racks[0].pens.length > 0 && isShowContentBasket ? 'active' : '',
                               basketZone.racks[0].pens.length > 3 ? 'scrolled' : '' ]"
                  >
                    <Item
                        v-for="(item, index) in shelf.pens"
                        :key="item.uuid + index"
                        :item="item"
                        :basketPos="basketPos"
                        :shelfId="shelf.uuid"
                        :zonetype="basketZone.type"
                        :isTouchDevice="isTouchDevice"
                    />
                  </ul>
                </vue-draggable-group>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="isColoredShelves" class="hint" :class="{ zooming: isZooming }">
          <img v-if="isShortHint" :src="require(`@/assets/img/hint2.png`)" alt="image hint">
          <img v-else :src="require(`@/assets/img/hint.png`)" alt="image hint">
        </div>
    </div>
</template>

<script>
    import Item from "./Item";
    import IconMinimize from "@/components/icons/IconMinimize";
    import IconMaximize from "@/components/icons/IconMaximize";
    import ArrowIcon from "@/components/icons/ArrowIcon";

    export default {
        name: "DragList",
        props: {
            options: { type: Object },
            initialZones: { type: Array },
            zones: { type: Array },
            basketZone: {type: Object},
            ready: { type: Boolean, default: false },
            scrollRef: { type: HTMLDivElement },
        },
        data() {
            return {
                basketPos: {},
                stellageZoomStyle: {
                    scale: 1,
                    x: 0,
                    y: 0,
                },
                isZooming: false,
                isFirstZoom: true,
                elementTargetNow: null,
                lastDistance: 0,
                scaleFactor: 0,
                scrollInterval: null,
                isShowContentBasket: false,
            };
        },
        components: { Item, IconMinimize, IconMaximize, ArrowIcon },
        computed: {
            isTouchDevice() {
                return (
                    "ontouchstart" in window ||
                    navigator.maxTouchPoints > 0 ||
                    navigator.msMaxTouchPoints > 0
                );
            },

            isColoredShelves() {
              let result = false

              this.zones.forEach(zone => {
                if (zone.racks.some(shelf => {
                  return !!shelf.color && shelf.color !== 'bazovyi-seryi'
                })) {
                  result = true
                }
              })

              return result
            },
            isShortHint() {
              let result = false

              this.zones.forEach(zone => {
                if (zone.racks.some(shelf => {
                  return shelf.color === 'ocishhenie-kozi-2' || shelf.color === 'uxod-za-kozei-2'
                })) {
                  result = true
                }
              })

              return result
            }
        },
        methods: {
          basketHovered() {
            this.$emit('basketHovered')
          },
          basketUnHovered() {
            this.$emit('basketUnHovered')
          },
          showContentBasket() {
            this.isShowContentBasket = !this.isShowContentBasket
            this.$emit('showContentBasket', this.isShowContentBasket);
          },
           getClassByColorName(colorName) {
              switch(colorName) {
                case 'sniatie-makiiaza' :
                  return 'make-up-removal'
                case 'ocishhenie-kozi' :
                  return 'skin-cleansing'
                case 'spec-ocishhenie' :
                  return 'special-cleansing'
                case 'spec-uxod' :
                  return 'special-care'
                case 'osnovnoi-uxod' :
                  return 'basic-care'
                case 'ocishhenie-kozi-2' :
                  return 'skin-cleansing2'
                case 'uxod-za-kozei-2' :
                  return 'skin-care'
                case 'dlia-problemnoi-kozi':
                  return 'acne'
              }
           },

            setInterval(interval) {
                if(this.scrollInterval) {
                    clearInterval(this.scrollInterval)
                }
                this.scrollInterval = interval
            },

            onMouseLeave() {
                if (this.scrollInterval) {
                    clearInterval(this.scrollInterval);
                }
            },

            onMouseEnter(float) {
                const SCROLL_SPEED = 5;
                const BORDER = {
                  top: this.inRem(40),
                  right: this.inRem(78),
                  bottom: this.inRem(97),
                  left: this.inRem(79)
                }
                let clientWidth, clientHeight;
                if(this.isZooming) {
                    clientWidth =  this.$refs.dragListWrapper.scrollWidth * 0.21 * this.stellageZoomStyle.scale;
                    clientHeight = this.$refs.dragListWrapper.clientHeight * 0.2 * this.stellageZoomStyle.scale;
                } else {
                    clientWidth =  this.$refs.dragListWrapper.scrollWidth * 0.14 * this.stellageZoomStyle.scale;
                    clientHeight = 0;
                }

                this.onMouseLeave();

                if(float === "top") {
                    this.scrollInterval = setInterval(() => {
                        if(this.stellageZoomStyle.y <= BORDER.top) {
                            clearInterval(this.scrollInterval);
                            return;
                        }
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x,
                            y: this.stellageZoomStyle.y - SCROLL_SPEED
                        };
                    }, 1)
                }

                if(float === "bottom") {
                    this.scrollInterval = setInterval(() => {
                        if(this.stellageZoomStyle.y >= clientHeight + BORDER.bottom) {
                            clearInterval(this.scrollInterval);
                            return;
                        }
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x,
                            y: this.stellageZoomStyle.y + SCROLL_SPEED
                        };
                    }, 1)
                }

                if(float === "right") {
                    this.scrollInterval = setInterval(() => {
                        if(this.stellageZoomStyle.x >= clientWidth + BORDER.right) {
                            clearInterval(this.scrollInterval);
                            return;
                        }
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x + SCROLL_SPEED,
                            y: this.stellageZoomStyle.y,
                        };
                    }, 1)
                }

                if(float === "right-top") {
                    this.scrollInterval = setInterval(() => {
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x >= clientWidth + BORDER.right ? this.stellageZoomStyle.x : this.stellageZoomStyle.x + SCROLL_SPEED,
                            y: this.stellageZoomStyle.y <= BORDER.top ? this.stellageZoomStyle.y : this.stellageZoomStyle.y - SCROLL_SPEED,
                        };
                    }, 1)
                }

                if(float === "right-bottom") {
                    this.scrollInterval = setInterval(() => {
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x >= clientWidth + BORDER.right ? this.stellageZoomStyle.x : this.stellageZoomStyle.x + SCROLL_SPEED,
                            y: this.stellageZoomStyle.y >= clientHeight + BORDER.bottom ? this.stellageZoomStyle.y : this.stellageZoomStyle.y + SCROLL_SPEED,
                        };
                    }, 1)
                }

                if(float === "left") {
                    this.scrollInterval = setInterval(() => {
                        if(this.stellageZoomStyle.x <= 0) {
                            clearInterval(this.scrollInterval);
                            return;
                        }
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x - SCROLL_SPEED,
                            y: this.stellageZoomStyle.y,
                        };
                    }, 1)
                }

                if(float === "left-top") {
                    this.scrollInterval = setInterval(() => {
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x <= 0 ? this.stellageZoomStyle.x : this.stellageZoomStyle.x - SCROLL_SPEED,
                            y: this.stellageZoomStyle.y <= BORDER.top ? this.stellageZoomStyle.y : this.stellageZoomStyle.y - SCROLL_SPEED,
                        };
                    }, 1)
                }

                if(float === "left-bottom") {
                    this.scrollInterval = setInterval(() => {
                        this.stellageZoomStyle = {
                            scale: this.stellageZoomStyle.scale,
                            x: this.stellageZoomStyle.x <= 0 ? this.stellageZoomStyle.x : this.stellageZoomStyle.x - SCROLL_SPEED,
                            y: this.stellageZoomStyle.y >= clientHeight + BORDER.bottom ? this.stellageZoomStyle.y : this.stellageZoomStyle.y + SCROLL_SPEED,
                        };
                    }, 1)
                }
            },

            onClickRack(e) {
                const target = e.target;
                const type = target.dataset.type;
                const zoom = 2.3;

                if (type === "basket" || target.classList.contains('drag-item__open')) {
                    return;
                }

                target.classList.add("target");
                this.elementTargetNow?.classList.remove("target");
                this.elementTargetNow = target;
                this.isZooming = true;

                const distance = this.calculateDistanceFromParent(
                    this.$refs.dragListWrapper.getBoundingClientRect(),
                    target.getBoundingClientRect()
                );

                const currentX = Math.floor(Math.abs(distance.x) * 0.8);
                const currentY = Math.floor(Math.abs(distance.y) * 0.8);

                if (this.isFirstZoom) {
                    this.stellageZoomStyle = {
                            scale: zoom,
                            x: currentX,
                            y: currentY,
                        };
                    this.isFirstZoom = false;
                    return;
                }

                this.stellageZoomStyle = {
                            scale: zoom,
                            x: currentX / zoom,
                            y: currentY / zoom,
                        };
            },

            onClickCloseZoom() {
                this.isZooming = false;
                this.isFirstZoom = true;
                this.elementTargetNow  = null;
                this.stellageZoomStyle = {
                      scale: 1,
                      x: 0,
                      y: 0,
                  };
            },

          onClickZoomFirst() {
            this.isZooming = true;
            this.isFirstZoom = false;

            const zoom = 2.3,
              distance = this.calculateDistanceFromParent(
                this.$refs.dragListWrapper.getBoundingClientRect(),
                this.$refs.rackBg[0].getBoundingClientRect()
              ),
              currentX = Math.floor(Math.abs(distance.x) * 0.8),
              currentY = Math.floor(Math.abs(distance.y) * 0.8);

              this.stellageZoomStyle = {
                scale: zoom,
                x: currentX,
                y: currentY,
              };
          }
        },
        watch: {
          isZooming(value) {
            this.$emit('isZoomed', value)
          }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/common";

    .wrapper {
        overflow: hidden;
        min-width: 1440rem;
        width: 100%;
        height: 100vh;
        padding: 68rem 78rem 97rem 79rem;
        background: url('../assets/img/bg.png') no-repeat;
        background-size: 100% 100%;
        @media (max-width: 769px) {
            overflow: scroll;
        }
    }

    .drag-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(1fr, auto));
        grid-template-areas: 'shelve-1 shelve-2 shelve-3';
        gap: 25rem;
        width: fit-content;
        min-width: 1283rem;
        max-height: 630rem;
        background-color: #FFFEFC;
        transform-origin: top left;
        transition: all 1s ease-out, transform-origin 0s;
        will-change: transform;

      &.basket {
        position: absolute;
        z-index: 11;
        display: block;
        width: 0;
        cursor: pointer;
      }

        &__zoom-button {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          z-index: 10;
          top: 50rem;
          right: 10vw;
          width: 48rem;
          height: 48rem;
          padding: 8rem;

          svg {
            width: 32rem;
            height: 32rem;
            stroke: $primary;
          }

          &:hover svg {
            stroke: $white;
          }
        }
    }

    .drag-column {
        position: relative;
        width: 100%;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &:first-child:not(.basket)::before, &:not(.basket)::after {
          content: '';
          position: absolute;
          top: 0;
          right: -25rem;
          width: 27rem;
          height: 693rem;
          background: url("../assets/img/rack.png") no-repeat 0% / cover;
        }

        &:first-child::before {
          left: -25rem;
          right: auto;
        }

        &.basket {
            position: absolute;
            left: -30rem;
            bottom: -94rem;
            width: 144rem;
            height: 83rem;

            @media (max-width: 769px) {
                display: none;
            }
        }
    }

    .drag-inner-list {
        display: grid;
        grid-template-rows: 108rem repeat(6, 72rem);
        gap: 15rem;
        align-items: end;
        height: auto;
        padding-bottom: 30rem;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .basket & {
            position: fixed;
            bottom: -102rem;
            left: -30rem;
            grid-template-rows: 1fr;
            width: 146rem;
            height: 83rem;
            max-width: unset;
            z-index: 40;
            overflow: visible;
            pointer-events: none;
            scrollbar-width: none;
            transition: all 0.25s ease-in-out;


          &::-webkit-scrollbar {
                display: none;
            }

            @media (max-width: 769px) {
                left: 50%;
                width: 330rem;
                height: 204rem;
                transform: translateX(-50%);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: url("../assets/img/Cart.svg");
                background-color: white;
                background-repeat: no-repeat;
                background-size: 50% auto;
                background-position: center;
                border-radius: 4px;
                border: 1px solid #5030AB;
                overflow: hidden;
                z-index: 2;
                scrollbar-width: none;
                transition: all 0.25s ease-in-out;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

          &.active {

            .basket-total {
              color: $primary;
              background-color: white;
            }

            &:after {
              background-image: url("../assets/img/Cart2.svg");
              background-color: $primary;
            }
          }

            .item-dropzone-area {
                position: absolute;
                display: block;
                width: 100%;
                height: 50%;
                align-self: flex-end;
                background: green;
                z-index: -1;
            }
        }
    }

    .drag-shelf {
        position: relative;
        height: 100%;
        cursor: pointer;
        transition: 0.2s;

        &::after {
            content: "";
            position: absolute;
            bottom: -15rem;
            display: block;
            width: 100%;
            height: 13rem;
            background-color: $shelf-color;
            border-width: .1rem 0;
            border-style: solid;
            border-color: $shelf-border;
            box-shadow: 0 6rem 8rem rgba(221, 220, 220, .5);
        }

      &.make-up-removal, &.skin-cleansing2 {
        background-color: #DDEFFB;
        &::after {
          background-color: #85c9f0;
        }
      }

      &.skin-cleansing {
        background-color: #C1ECEA;
        &::after {
          background-color: #2cbcbf  ;
        }
      }

      &.special-cleansing {
        background-color: #F8EDFB;
        &::after {
          background-color: #e3c1f2;
        }
      }

      &.special-care, &.skin-care {
        background-color: #F9EAEF;
        &::after {
          background-color: #ebb5c8;
        }
      }

      &.basic-care {
        background-color: #E6E4F2;
        &::after {
          background-color: #a89fd0;
        }
      }

      &.acne::after {
        background-color: #00AF50;
      }

      &__background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: zoom-in;
        transition: 0.2s;
        padding-top: 45rem;
        opacity: 0.4;
        &:hover {
            background: rgba(80, 48, 171, 0.2);
        }
        @media (max-width: 768px) {
            display: none;
        }
      }

      .basket & {
        height: 83rem;

        @media (max-width: 768px) {
            height: 80%;
        }

        &::after {
            content: none;
        }
        }
    }

    .hint {
      position: fixed;
      bottom: 15rem;
      left: 210rem;
      width: 560rem;
      height: 70rem;
      transition: all 0.5s linear;

      &.zooming {
        bottom: 48rem;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    .drag-shelf-content {
        position: relative;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 100%;
        padding: 0 4.5rem;
        z-index: 2;
        transition: 0.2s;

        .basket & {
            position: relative;
            top: 0;
            transform: translateY(calc(83rem - 100%));
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 83rem 0;
            pointer-events: all;
            height: 83rem;
            border-radius: 4px;
            overflow-y: hidden;
            overflow-x: hidden;

            &.scrolled {
              overflow-y: scroll;

              &::-webkit-scrollbar {
                display: block;
                width: 26px;
                background-color: transparent;
              }
              &::-webkit-scrollbar-track {
                background: white;
              }

              &::-webkit-scrollbar-thumb {
                width: 6px;
                border: 10px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                border-radius: 9999px;
                background-color: #AAAAAA;
              }

              &::-webkit-scrollbar-track-piece:end {
                background: transparent;
                margin-bottom: 10rem;
              }

              &::-webkit-scrollbar-track-piece:start {
                margin-top: 10rem;
                border-radius: 50px;
              }
            }

          &.active {
            padding: 0;
            top: -171rem;
            height: 335rem;
          }
        }
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }
        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 6rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }


    .scroll-button {
        position: fixed;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($white, 0.9);

        @media (max-width: 768px) {
         display: none;
        }

        &--right {
          top: 50%;
          transform: translateY(-50%);
          width: 48rem;
          right: 0;
          height: 50vh;
          border-radius: 8rem 0 0 8rem;

        }
        &--left {
          top: 50%;
          transform: translateY(-50%);
          width: 48rem;
          left: 0;
          height: 50vh;
          border-radius: 0 8rem 8rem 0;

          svg {
            transform: rotate(180deg);
          }
        }
        &--bottom {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50vw;
          height: 48rem;
          border-radius: 8rem 8rem 0 0;

          svg {
            transform: rotate(90deg);
          }
        }
        &--top {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50vw;
          height: 48rem;
          border-radius: 0 0 8rem 8rem;

          svg {
            transform: rotate(-90deg);
          }
        }
        &--right-top {
          position: absolute;
          top: 0;
          right: 0;
          width: 48rem;
          height: 48rem;
          border-radius: 0 0 0 8rem;
        }
        &--right-bottom {
          bottom: 0;
          right: 0;
          width: 48rem;
          height: 48rem;
          border-radius: 8rem 0 0 0;
        }
        &--left-top {
          top: 0;
          left: 0;
          width: 48rem;
          height: 48rem;
          border-radius: 0 0 8rem 0;
        }
        &--left-bottom {
          bottom: 0;
          left: 0;
          width: 48rem;
          height: 48rem;
          border-radius: 0 8rem 0 0;
        }
    }

    .basket-total {
      position: absolute;
      top: 4rem;
      right: 25rem;
      padding: 3px 11px 2px 11px;
      background-color: $primary;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      z-index: 5;
      font-size: 11rem;
      line-height: 1.1;
      font-weight: 700;
    }

</style>

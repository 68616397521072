<template>
  <div class="info">
    <div class="info__text" v-if="screenNumber === 1">
      <transition name="fade" mode="out-in">
        <div v-if="startInfoNum === 0" key="start">
          <p>Вы пришли в магазин косметики и&nbsp;товаров по уходу за собой.</p>
          <p><strong>Пожалуйста, совершите Вашу обычную покупку, как Вы это делаете в обычной жизни.</strong></p>
        </div>
        <div v-else-if="startInfoNum === 1" key="instruction">
          <p>Нажмите на товар и вы сможете увидеть информацию о нем.</p>
          <p><strong>Для совершения покупки, положите товар в корзину.</strong></p>
          <p>Чтобы убрать товар из корзины нажмите Х.</p>
        </div>
        <div v-else key="zooming" class="zoomimg">
          <p>Нажмите на область за продуктами (на полках) или на иконку зума, чтобы увеличить полку:</p>
          <div class="zooming__buttons">
            <div class="zooming__button">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 2.5H17.5V7.5" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 17.5H2.5V12.5" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.4998 2.5L11.6665 8.33333" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 17.4998L8.33333 11.6665" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="zooming__button">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.3335 11.6665H8.3335V16.6665" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.6665 8.3335H11.6665V3.3335" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.6665 8.33333L17.4998 2.5" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 17.4998L8.33333 11.6665" stroke="#5030AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
          <p>Для перемещения пользуйтесь элементами навигации на боковых панелях экрана:</p>
          <img :src="require(`@/assets/img/zoom-buttons.png`)" alt="Инструкция по зуму" class="zooming__image">
        </div>
      </transition>
    </div>

    <div class="info__text" v-else-if="screenNumber === 2">
      <p>Вы снова оказались в магазине косметики и товаров по уходу за собой, чтобы сделать покупки.</p>
      <br>
      <p v-if="(con === 2 || con === 4) && screenNumber === 2">
        <strong>Но на этот раз товары выложены иначе, обратите внимание на навигацию.</strong>
      </p>
      <img v-if="con === 2 && screenNumber === 2" :src="require(`@/assets/img/hint.png`)" alt="Инструкция">
      <img v-if="con === 4 && screenNumber === 2" :src="require(`@/assets/img/hint2.png`)" alt="Инструкция">
      <br v-if="(con === 2 || con === 4) && screenNumber === 2">
      <p><strong>Пожалуйста, совершите Вашу следующую покупку.</strong></p>
    </div>

    <transition name="fade" mode="out-in">
      <button class="info__button button"
              @click="clickButton"
              key="start-button"
              v-if="screenNumber === 1 && startInfoNum === 0">
        Продолжить
      </button>
      <div v-else-if="screenNumber === 1 && startInfoNum === 1" class="info__buttons">
        <button class="info__button button" @click="startInfoNum = 0" key="instruction-button">
          Назад
        </button>
        <button class="info__button button" @click="clickButton" key="instruction-button2">
          Продолжить
        </button>
      </div>
      <button v-else-if="screenNumber === 2" class="info__button button" @click="clickButton" key="instruction-button3">
        Продолжить
      </button>
      <button class="info__button button _secondary" @click="clickButton" key="instruction-button4" v-else>
        Начать
      </button>
    </transition>

    <ul class="dots" v-if="screenNumber === 1">
      <li v-for="(dot, i) in 3" :key="i" :class="{'_active': startInfoNum === i}"
      ></li>
    </ul>
  </div>
</template>

<script>
import {eventBus} from "@/main";


export default {
  name: "Info",
  props: {
    screenNumber: { type: Number, required: true },
    con: { type: Number, default: null }
  },
  data() {
    return {
      startInfoNum: 0,
    }
  },
  methods: {
    clickButton() {
      this.startInfoNum++;
      if (this.screenNumber === 1 && this.startInfoNum < 3) {

        if (this.startInfoNum === 2) {
          // modal is not closing outside until you see all starting messages
          eventBus.$emit('can close outside', true);
        }
      } else {
        eventBus.$emit('close modal', {});
      }
    },
  },
  created () {
    // if this is a start screen popup you can't close modal by clicking outside
    if (this.screenNumber === 1 && !this.startInfoNum) eventBus.$emit('can close outside', false);
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/common";

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 12rem;

  &__text {
    width: 290rem;
    margin-right: 20rem;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    p {
      margin-bottom: 6rem;
    }

    img {
      width: 100%;
      margin: auto;
      display: block;
    }
  }

  &__buttons {
    display: flex;
    gap: 12rem;
    width: 100%;

    button {
      flex: 1;
    }
  }

  &__button {
    width: 100%;
    margin-top: 24rem;
    cursor: pointer;
  }
}

.zooming {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20rem;
    margin: 16rem 0;
  }

  &__button {
    width: 36rem;
    height: 36rem;
    padding: 7rem;
    border: 1rem solid $primary;
    border-radius: 4rem;

    svg {
      width: 20rem;
      height: 20rem;
      stroke: $primary;
    }
  }

  &__image {
    width: 200rem!important;
    height: 80rem;
  }
}

.dots {
  display: flex;
  gap: 4rem;
  margin-top: 16rem;

  li {
    width: 8rem;
    height: 8rem;
    border: 1rem solid $black;
    border-radius: 50%;
    transition: border-color .35s ease-out, background-color .35s ease-out;
    overflow: hidden;

    &._active {
      border-color: $primary;
      background-color: $primary;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

</style>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 4H28V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 28H4V20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.0001 4L18.6667 13.3333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 28.0001L13.3333 18.6667" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconMaximize"
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./StartInfo.vue?vue&type=template&id=08263ff4&scoped=true&"
import script from "./StartInfo.vue?vue&type=script&lang=js&"
export * from "./StartInfo.vue?vue&type=script&lang=js&"
import style0 from "./StartInfo.vue?vue&type=style&index=0&id=08263ff4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08263ff4",
  null
  
)

export default component.exports
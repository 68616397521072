<template>
  <div class="horizontal-scroll"
       @wheel.prevent ="wheel"
       @scroll.prevent ="wheel"
       ref="horizontal">
    <div class="js-rem-example" ref="remEx"></div>
    <DragList
        v-if="zones && zones.length > 0"
        :initialZones="initialZones"
        :zones="zones"
        :basketZone="basketZone"
        :ready="ready"
        :options="options"
        :scrollRef="$refs.horizontal"
        @isZoomed="isZoomed"
        @showContentBasket="toggleShowContentBasket"
        @basketHovered="isBasketHovered = true"
        @basketUnHovered="isBasketHovered = false"
    />
    <div class="basket-price button" v-if="showContentBasket && basketZone.racks[0].pens.length > 0">
      Стоимость покупки: <span>{{ basketPrice }} &#8381;</span>
    </div>
    <a class="link-button button" @click="nextScreen" v-if="basketItemsQnty && !isZooming">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path d="M4.467 16.8H2.1a.7.7 0 1 0 0 1.4h2.8a.7.7 0 0 0 .626-.387l.774-1.548 2.174 4.348a.7.7 0 1 0 1.252-.626L7.082 14.7l.704-1.408.731 1.096a.7.7 0 0 0 .583.312h2.1a.7.7 0 1 0 0-1.4H9.475L8.282 11.51A.714.714 0 0 0 7.7 11.2H4.9a.717.717 0 0 0-.626.387l-1.05 2.1a.7.7 0 0 0 .313.939c.341.17.768.028.939-.313l.856-1.713h1.235l-2.1 4.2ZM7.7 9.8c0 .772.628 1.4 1.4 1.4.772 0 1.4-.628 1.4-1.4 0-.772-.628-1.4-1.4-1.4-.772 0-1.4.628-1.4 1.4ZM13.3 14a.7.7 0 0 0 .7.7h7.41l-2.305 2.305a.7.7 0 1 0 .99.99l3.5-3.5a.7.7 0 0 0 0-.99l-3.5-3.5a.7.7 0 1 0-.99.99L21.41 13.3H14a.7.7 0 0 0-.7.7Z"/>
        <path d="M25.9 5.6h-9.8a.7.7 0 0 0-.7.7v4.9a.7.7 0 1 0 1.4 0V7h8.4v14h-8.4v-4.2a.7.7 0 1 0-1.4 0v4.9a.7.7 0 0 0 .7.7h9.8a.7.7 0 0 0 .7-.7V6.3a.7.7 0 0 0-.7-.7Z"/>
      </svg>
      Завершить покупки
    </a>
    <Modal :item="item"
           :posModal="posModal"
           :displayStartInfo="displayStartInfo"
           :screenNumber="screenNumber"
           v-if="showModal && (displayDetails || displayStartInfo)">
      <ItemDetails :item="item"
                   :posModal="posModal"
                   :shelfId="shelfId"
                   :basketItemsQnty="basketItemsQnty"
                   v-if="displayDetails"/>
      <StartInfo :screenNumber="screenNumber" :con="con" v-else-if="displayStartInfo"/>
    </Modal>
    <MobileDrag :src="mobileSrc" :pos="mobilePos" v-if="mobileSrc"/>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import Modal from "@/components/Modal";
import DragList from "@/components/DragList";
import ItemDetails from "@/components/modal-content/ItemDetails";
import StartInfo from "@/components/modal-content/StartInfo";
import MobileDrag from "@/components/MobileDrag";

export default {
  name: 'Shelves',
  data() {
    return {
      id: '',
      con: '',
      screenId: null,
      ready: false,
      showContentBasket: false,
      maxScrollLeft: null,
      scrollGap: null,
      isTouch: false,
      scrollInterval: null,
      isBasketHovered: false,
      screens: [],
      initialZones: [],
      screenNumber: 1,
      zones: [],
      basketZone: {
        id: '0',
        name: "basket",
        type: 'basket',
        racks: [
          {
            id: '0',
            uuid: '0',
            pens: []
          }
        ]
      },
      basketProductsIds: '',
      shownProductsIds: [],
      item: {},
      showModal: true,
      displayStartInfo: false,
      posModal: null,
      shelfId: '',
      mobileSrc: '',
      mobilePos: {
        top: 0,
        left: 0
      },

      isZooming: false,

      options: {
        dropzoneSelector: ".drag-shelf-content",
        draggableSelector: window.innerWidth > 768 ? ".drag-item" : "",
        onDragstart: (e) => {
          let oldGroupId = e.owner.dataset.id
          let elementId = e.items[0].dataset.id
          if (oldGroupId == 0 && e.droptarget === null) {
            setTimeout(() => {
              this.removeElementFromBasket(elementId)
            }, 500)
          }
        },
        onDragend: async (e) => {
            let newGroupId = e.droptarget.dataset.id
            let oldGroupId = e.owner.dataset.id
            let elementId = e.items[0].dataset.id
            if (newGroupId == oldGroupId) {
                e.stop()
            } else if (newGroupId == 0 && oldGroupId != 0) {
              this.putElementToBasket(oldGroupId, elementId)
              if (this.$gtm.enabled()) {
                this.$gtm.trackEvent({
                  event: 'Basket-Add',
                  category: 'Basket',
                  action: 'Add',
                  label: elementId,
                  value: new Date(),
                })
              }

              // track
              try {
                await this.track({
                  e: e.items[0],
                  event: "add_to_basket",
                  screen: this.screenNumber,
                })
              } catch (e) {
                console.error(e)
              }
            } else if (oldGroupId == 0) {
              this.removeElementFromBasket(elementId)
            } else {
              e.stop()
            }
            e.stop()
        }
      },
      scrolledBasketElementPosition: 0
    };
  },
  components: {
    Modal,
    DragList,
    ItemDetails,
    StartInfo,
    MobileDrag
  },
  methods: {
    wheel(e) {
      if (this.isBasketHovered) {
        let basket = document.getElementById('basket')
        let basketElements = basket.getElementsByTagName('li')
        if (e.deltaY > 0) {
          if (this.scrolledBasketElementPosition < basketElements.length) {
            this.scrolledBasketElementPosition++
          }
        } else {
          if (this.scrolledBasketElementPosition > 0) {
            this.scrolledBasketElementPosition--
          }
        }
        basketElements[this.scrolledBasketElementPosition].scrollIntoView()
      } else {
        if (e.deltaY) {
          this.$refs.horizontal.scrollLeft += e.deltaY;
        } else if (e.deltaX) {
          this.$refs.horizontal.scrollLeft += e.deltaX;
        }
      }

    },

    toggleShowContentBasket(showContentBasket) {
      this.showContentBasket = showContentBasket
    },

    async getBackendData(con) {
      await this.fetchData(con);
      if (this.screenNumber === 2) {
        this.screenId = this.screens[this.screenNumber - 1];
        await this.fetchData(this.con, this.screenId);
      }

      setTimeout(() => {
        this.maxScrollLeft = this.$refs.horizontal.scrollWidth - this.$refs.horizontal.clientWidth;
        this.scrollGap = this.maxScrollLeft / 10;
      }, 500)
    },

    async fetchData(con, screenId) {
      try {
        await this.$http.get(`${process.env.VUE_APP_API_URL}/api/scripts/${con}${screenId ? '/' + screenId : ''}`)
            .then(response => {
              return response.data;
            })
            .then(data => {
              if (data.zones ) {
                this.screens = data.screen_ids;

                if (this.screenNumber === 1 || (this.screenNumber === 2 && screenId) ) {
                  this.initialZones = data.zones;
                  this.zones = this.cloneOverJson(this.initialZones);
                  this.basketZone.racks = [{
                    id: '0',
                    uuid: '0',
                    pens: []
                  }]
                }
              }
            });
      } catch (error) {
        console.log(error);
      }
    },

    putElementToBasket(oldGroupId, elementId) {
      if (this.basketZone.racks[0].pens.length < 10) {
        const {zoneIndex, groupIndex, elementIndex} = this.findElement(elementId, this.zones, oldGroupId, 'put');
        const clonedElement = this.cloneOverJson(this.zones[zoneIndex].racks[groupIndex].pens[elementIndex]);
        // this.zones[zoneIndex].racks[groupIndex].pens[elementIndex].image = '';
        this.basketZone.racks[0].pens.push(clonedElement);
      }
    },
    removeElementFromBasket(elementId) {
      /*const {zoneIndex, groupIndex, elementIndex} = this.findElement(elementId, this.initialZones, elementId, 'remove')

      const removedElement = this.cloneOverJson(this.basketZone.racks[0].pens
          .splice(this.basketZone.racks[0].pens.findIndex(el => el.uuid == elementId), 1)[0])

      this.zones[zoneIndex].racks[groupIndex].pens[elementIndex].image = removedElement.image*/

      this.cloneOverJson(this.basketZone.racks[0].pens
          .splice(this.basketZone.racks[0].pens.findIndex(el => el.uuid == elementId), 1)[0]);
    },
    findElement(elementId, zones, id, action) {
      const zoneIndex = action === 'put'
          ? zones.findIndex(zone => zone.racks.some(group => group.uuid == id))
          : zones.findIndex(zone => zone.racks.some(group => group.pens.some(el => el.uuid == elementId)));
      const groupIndex = action === 'put'
          ? zones[zoneIndex].racks.findIndex(group => group.uuid == id)
          : zones[zoneIndex].racks.findIndex(group => group.pens.some(el => el.uuid == elementId));
      const elementIndex = zones[zoneIndex].racks[groupIndex].pens.findIndex(el => el.uuid == elementId);
      return {zoneIndex, groupIndex, elementIndex};
    },

    async nextScreen() {

      // track shown (mouse over) products
      try {
          await this.track({
              screen: this.screenNumber,
              event: 'next_screen',
              shown: this.shownProductsIds
          })
      } catch (e) {
          console.error(e)
      } finally {
          this.shownProductsIds = []
      }

      const arr = this.basketZone.racks[0].pens.map(pen => pen.sku);
      this.basketProductsIds += this.basketProductsIds ? '%2C' + arr.join('%2C') : arr.join('%2C');
      const skipto = this.screenNumber === 2 ? '&sys_skipto=Q1x2' : '&sys_skipto=Q1'
      window.location.href = 'https://srv.kg/05/KBR2307/cgi-bin/ciwweb.pl?studyname=KBR2307&ge=1&ID='
          + this.id
          + '&prodIds=' + this.basketProductsIds
          + skipto;
    },

    modalPosition(data) {
      let pos = data.pos

      if (data.pos && this.$refs.horizontal) {
        const modalWidth = {
          top: this.$refs.horizontal.offsetHeight - this.inRem(90),
          left: this.inRem(345 / 2)
        };
        let left = `${data.pos.left}px`;

        if (this.$refs.horizontal.offsetWidth - this.inRem(345 / 2) < data.pos.left) {
          left = `${this.$refs.horizontal.offsetWidth - this.inRem(345 / 2)}px`
        } else if (modalWidth.left > data.pos.left) {
          left = `${modalWidth.left}px`;
        }

        pos = {
          top: (modalWidth.top - data.pos.top < this.inRem(90)) ? `${modalWidth.top}px` : `${data.pos.top + this.inRem(90)}px`,
          left: left
        };

        setTimeout(() => {
          if (this.posModal) {
            this.showModal = true;
          }
        }, 200)
      } else {
        this.showModal = true;
      }

      this.item = data.item;
      this.posModal = pos;
      if (data.shelfId) {
        this.shelfId = data.shelfId;
      }
    },

    windowWidthCount() {
      this.isTouch = (window.innerWidth < 769) ||
      (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0))
          ? true : false;
    },

    isZoomed(value) {
      this.isZooming = value;
    }

  },
  computed: {
    // defines if it's an item details popup or message popup
    displayDetails() {
      return this.item && Object.keys(this.item).length > 0
    },
    // products quantity in basket
    basketItemsQnty() {
      return this.basketZone.racks[0].pens ? this.basketZone.racks[0].pens.length : 0
    },
    basketPrice() {
      return this.basketZone.racks[0].pens ?
          this.basketZone.racks[0].pens.reduce((acc, item) => {
            const price = item.price.replace(/,/g, '.').replace(/ /g, '');
            return Math.round((acc + +price) * 10) / 10;
          }, 0)
          : 0
    }
  },
  mounted() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    this.id = url.searchParams.get("ID");
    this.con = +url.searchParams.get("con");
    this.screenNumber = +url.searchParams.get("part");

    this.getBackendData(this.con);

    window.addEventListener('load', this.windowWidthCount)

    window.addEventListener('resize', this.windowWidthCount)
  },
  created() {
    eventBus.$on('open modal', data => {
      if (data.pos != null) {
        this.shownProductsIds.push(data.item.id)
      }

      this.modalPosition(data)
    })
    eventBus.$on('close modal', () => {
      this.item = {};
      this.showModal = false;
      this.displayStartInfo = false;
    })
    eventBus.$on('remove item', elementId => {
      this.removeElementFromBasket(elementId);
      if (this.item.uuid === elementId) {
        this.item = {};
        this.showModal = false;
      }
    })
    eventBus.$on('mobile drag', (data) => {
      this.mobileSrc = data.src;
      this.mobilePos.top = data.pos.top;
      this.mobilePos.left = data.pos.left;
    })
    eventBus.$on('move to basket', async (data) => {
        this.putElementToBasket(data.shelf, data.uuid)

        // track
        try {
          await this.track({
            e: document.querySelectorAll(`li[data-id="${data.uuid}"]`)[0],
            event: "add_to_basket",
            screen: this.screenNumber,
          })
        } catch (e) {
            console.error(e)
        }
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.windowWidthCount)
  }
};
</script>

<style lang="scss">
@import "../assets/scss/common";

.horizontal-scroll {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.drag-container {
  position: relative;
  height: 100vh;
  width: max-content;

}

.link-button {
  position: fixed;
  right: 53rem;
  bottom: 20rem;
  padding: 14rem 27rem;
  border-bottom-right-radius: 8rem;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    top: 16rem;
    right: 16rem;
    bottom: auto;
    width: calc(100% - 32rem);
    padding: 14rem;
  }

  svg {
    width: 28rem;
    height: 28rem;
    fill: $primary;
  }

  &:hover svg {
    fill: $white;
  }
}

.basket-price {
  position: fixed;
  left: 50rem;
  bottom: 96rem;
  pointer-events: none;
  width: 146rem;
  height: 82rem;
  text-align: left;
  font-size: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.2;
  z-index: 40;

  span {
    font-size: 20rem;
    font-weight: 700;
    line-height: 1;
  }
}

</style>
